<template>
  <v-navigation-drawer
    v-model="inputValue"
    fixed
    right
    temporary
  >
    <v-row
      align="center"
      class="ma-0 pa-3 flex-no-wrap"
    >
      <v-btn
        aria-label="Close"
        class="ml-4"
        icon
        @click="toggleDrawer"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-divider />
    <v-select
      v-model="language"
      :items="languages"
      return-object
      filled
      label="Language"
      persistent-hint
      @change="changeLocale(language.value)"
    />

    <v-list>
      <div
        v-for="(item, i) in items"
        :key="i"
        exact
      >
        <v-list-item
          v-if="!item.inner || $router.currentRoute.name === 'products'"
          :to="'/' + $i18n.locale + item.to"
        >
          <v-list-item-title style=" text-transform: capitalize">
            {{ $t(item.text) }}
          </v-list-item-title>
        </v-list-item>
        <v-list-group
          v-if="item.inner && $router.currentRoute.name != 'products'"
          :value="true"
        >
          <template #activator>
            <v-list-item-title style=" text-transform: capitalize">
              {{ $t(item.text) }}
            </v-list-item-title>
          </template>

          <v-list-item
            v-for="(innerItem, i) in item.inner"
            :key="i"
            :to="{ name: innerItem.to, params:innerItem.params,query:innerItem.query}"
          >
            <v-list-item-icon />
            <v-list-item-title>{{ $t(innerItem.text) }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import { mapState, mapMutations } from 'vuex'
  import i18n from '../../i18n'

  export default {
    data: () => ({

      language: { value: i18n.locale },
      languages: [
        { value: 'en', text: 'English' },
        { value: 'fr', text: 'Français' },
        { value: 'de', text: 'Deutsch' },
      ],
      items: [
        {
          to: '/?home',
          text: 'menu.home',
        },

        { text: 'menu.company_overview', to: '/about-us' },
        { text: 'menu.companies_we_represent', to: '/about-us/companies-we-represent' },

        { text: 'menu.news_articles', to: '/about-us/news' },
        {
          to: '/products',
          text: 'menu.products',

          inner: [
            {
              text: 'home.soil_preparation',
              to: 'products',
              params: { category: 'soil-preparation', lang: i18n.locale },
              query: { category: 'soil-preparation' },

            },
            {
              text: 'home.planting_seeding',
              to: 'products',
              params: { category: 'planting-and-seeding', lang: i18n.locale },
              query: { category: 'planting-and-seeding' },
            },
            {
              text: 'home.crop_care',
              to: 'products',
              params: { category: 'crop-care', lang: i18n.locale },
              query: { category: 'crop-care' },
            },
            {
              text: 'home.harvest',
              to: 'products',
              params: { category: 'harvest', lang: i18n.locale },
              query: { category: 'harvest' },
            },
            {
              text: 'home.vineyard',
              to: 'products',
              params: { category: 'vineyard', lang: i18n.locale },
              query: { category: 'vineyard' },
            },
            {
              text: 'home.other',
              to: 'products',
              params: { category: 'other', lang: i18n.locale },
              query: { category: 'other' },
            },
          ],

        },
        {
          to: '/contact',
          text: 'menu.contact',
        },

      ],
    }),
    computed: {
      ...mapState(['drawer']),
      inputValue: {
        get () {
          return this.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
    },
    created () {
      this.language = { value: (this.$router.params ? this.$router.params.lang : i18n.locale) }
    },

    methods: {
      ...mapMutations(['setDrawer', 'toggleDrawer']),
      changeLocale (locale) {
        i18n.locale = locale
        this.$router.push({ name: this.$router.currentRoute.name, params: { lang: locale } })
      },
    },
  }
</script>
